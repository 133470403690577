import { VERSION } from './version';

export const environment = {
  apiServerBase: location.origin,
  urlBasePrefix: 'https://notd.transact.io',
  gitHash: VERSION.hash,
  buildDate: VERSION.timestamp,
  production: true,
  googleMapsAPIKey: 'AIzaSyDf4XiD03mWFPwPUL2o2U107ejtFDNjRkY',
  transactBaseUrl: 'https://test.xsact.com',
  gtm_id: 'GTM-KDH9MPC'
};
